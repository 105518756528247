


module.exports = {
    statusMappings: {
    	"CLEAR": {
	    	showsOnMap: true,
	    	color: "green",
	    	humanTitle: "Clear",
	    	humanDescription: "The area is clear"
	    },
	    "NEAR_FLOOD": {
	    	showsOnMap: true,
	    	color: "#dd7520",
	    	humanTitle: "Close to Flooding.",
	    	humanDescription: "The area is close to flooding. The water level is close to our calculated height at which this area begins to flood, our land elevations are also estimates. As such, it may be possible that this is dangerous to pass or starting to flood. Take care, especially if the water levels are rising."
	    },
	    "RECENTLY_FLOODED": {
	    	showsOnMap: true,
	    	color: "#964B00",
	    	humanTitle: "Recently Flooded",
	    	humanDescription: "The area has been flooded recently. It may be slippy and/or silty, but likely passable."
	    },
	    "IN_PARTIAL_FLOOD": {
	    	showsOnMap: false,
	    	color: "orange",
	    	humanTitle: "Partially Flooded",
	    	humanDescription: "Part of this area is flooded. Use the pointers on our map to determine if the section you want to use is passable. It is inadvisable to attempt to pass, especially if the water levels are rising. If the water levels are falling, it is likely that the area is slippy and silty."
	    },
	    "IN_FLOOD": {
	    	showsOnMap: true,
	    	color: "red",
	    	humanTitle: "Flooded",
	    	humanDescription: "The area is flooded."
	    },
	    "CLOSED": {
	    	showsOnMap: true,
	    	color: "red",
	    	humanTitle: "Closed",
	    	humanDescription: "The area/attraction is closed."
	    },
	    "NONE": {
	    	showsOnMap: false,
	    	color: "green",
	    	humanTitle: "Looks OK",
	    	humanDescription: "The area/attraction has no noted closures."
	    }
	}, statusText: {
		"CLEAR": "CLEAR",
		"RECENTLY_FLOODED": "RECENTLY FLOODED",
		"IN_PARTIAL_FLOOD": "PARTIALLY FLOODED",
		"IN_FLOOD": "FLOODED",
		"CLOSED": "CLOSED",
		"NONE": "NONE",
	}
  };