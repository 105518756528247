import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Water } from 'react-bootstrap-icons';

function RiverGauge(props) {
	return (
		<Row style={{paddingBottom: "3px"}} className="text-center">
			
			<Col xs={3}>{props.title || ""}</Col>
			<Col xs={4}><b><Water /> {Math.floor(100*props.entryHeight)/100}m</b></Col>
			<Col xs={5}>@ {props.readingTime || ""}</Col>
			
		</Row>
	);
}

RiverGauge.Tiny = function(props) {
	return (
		<Row style={{paddingBottom: "3px"}}>
			<Col xs={3}><b><Water /> <i>{props.title || ""}</i></b></Col>
			<Col xs={4} className="text-center"><b>{Math.floor(100*props.entryHeight)/100}m</b></Col>
			<Col xs={5} className="text-center">@ {props.readingTime || ""}</Col>
		</Row>
	);
}


export default RiverGauge;