



function StationNameRenderer({ stationName, qualifierType }) {
	let computedStationName = stationName;
	if(qualifierType && qualifierType === 'Downstream Stage') {
		computedStationName = stationName + " (Downstream Stage)";
	}

	return <span>{computedStationName}</span>;
}


export default StationNameRenderer;