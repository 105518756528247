import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import statusInfo from '../lib/statusInfo.js';
const statusText = statusInfo.statusText;

function FloodAttractionModal({ activeFAModel, mfaKey, mfa, onHide, classes, styles }) {

	return <Modal show={activeFAModel === mfaKey} contentClassName="bg-dark text-white" onHide={onHide}>
		        <Modal.Header variant="dark" closeButton>
		          <Modal.Title className="text-white"><h2 className="hX-blendin">{mfa.name}</h2></Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Row className="text-white">
		        		<Col md={12} className={classes.join(", ")} style={styles}><h3><center>{statusText[mfa.status] || "Unknown"}</center></h3></Col>
		        	</Row>
		        	<hr />
		        	{mfa.description && <div><Row className="text-white">
		        		<Col xs={12}><b>Reason Given: <i>{mfa.title}</i></b></Col>
		        		<Col xs={12}><i>{mfa.description}</i></Col>

		        	</Row><hr /></div>}
		        	<Row className="text-white">
		        		<Col xs={12} className="d-flex justify-content-center"><span><b>Information Source:</b> {mfa.attribution}</span></Col>
		        	</Row>
		        </Modal.Body>
	      </Modal>;
}

export default FloodAttractionModal;