import React, { useEffect } from 'react';

const AdComponent = ({ client, slot, format, responsive, layoutKey, style = {display: 'block'} }) => {
  useEffect(() => {
  	if(process.env.REACT_APP_ENABLE_GOOGLE_ADS === "true") {
    	try {
        if (typeof window !== 'undefined') {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
      } catch(error) {
        console.error("Unable to render google advert", error);
      }
    }
  }, []);


  if(!process.env.REACT_APP_ENABLE_GOOGLE_ADS === "true") { return null; }

  return (
    <ins className="adsbygoogle"
    	data-ad-layout-key={layoutKey}
		style={style}
		data-ad-client={client}
		data-ad-slot={slot}
		data-ad-format={format}
		data-full-width-responsive={responsive && responsive.toString()}
    ></ins>
  );
};

export default AdComponent;
